import gql from 'graphql-tag'


export const LIST_TRIAL_BALANCE = (templateType) => gql`query LIST_TRIAL_BALANCE ($accountTrialBalanceType: String!, $startDate: Date!, $endDate: Date!) {
  data: list${templateType} (accountTrialBalanceType: $accountTrialBalanceType, startDate: $startDate, endDate: $endDate) {
    broughtForwardAccounts {
      id code name amount
    }
    betweenPeriodAccounts {
      id code name parentId
      amount total
      docConfigs ledgers
    }
  }
}`

export const LIST_DOC = (templateType) => gql`query LIST_DOC ($accountTrialBalanceType: String!, $accountId: Int!, $startDate: Date!, $endDate: Date!) {
  data: list${templateType}Doc (accountTrialBalanceType: $accountTrialBalanceType, accountId: $accountId, startDate: $startDate, endDate: $endDate) {
    broughtForward
    docs {
      id type code name remark amount balance
      closedAt closedBy closedUser {id name}
    }
    carryForward
  }
}`

export const DP_ACCOUNTS = (templateType) => gql`query DP_ACCOUNTS ($accountTrialBalanceType: String!, $q: FilterInput) {
  items: list${templateType}DropdownAccount (accountTrialBalanceType: $accountTrialBalanceType, q: $q) {
    id code name
  }
}`