<template>
  <button
    @click="download"
    class="btn btn-success">
    <fa icon="download"></fa>
    Excel
  </button>
</template>

<script>
import round from 'lodash/round'
import xlsx from 'json-as-xlsx'

export default {
  props: {
    account: {
      type: Object,
      required: true
    },
    docs: {
      type: Array,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    broughtForward: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      sheetname: '000000',
      columns: [
        { label: 'รหัสบัญชี', value: 'accountCode' },
        { label: 'ชื่อบัญชี', value: 'accountName' },
        { label: 'รหัสเอกสาร', value: 'docCode' },
        { label: 'วันที่', value: 'docClosedAt' },
        { label: 'เดบิต', value: 'debit' },
        { label: 'เครดิต', value: 'credit' },
        { label: 'คงเหลือ Dr(+)/Cr(-)', value: 'balance' },
        { label: 'รายละเอียด', value: 'docName' },
        { label: 'หมายเหตุ', value: 'docRemark' },
      ]
    }
  },
  computed: {
    filename () {
      return `${this.account.code}_${this.account.name}_${this.startDate}_${this.endDate}`
    },
    rows () {
      const content = this.docs.map(doc => {
        return {
          accountCode : this.account.code,
          accountName: this.account.name,
          docCode: doc.code,
          docClosedAt: this.$date.format(doc.closedAt).displayThai,
          debit : round(doc.amount > 0 ? doc.amount : 0, 2),
          credit: round(doc.amount < 0 ? doc.amount * -1 : 0, 2),
          balance: round(doc.balance, 2),
          docName: doc.name,
          docRemark: doc.remark,
        }
      })

      content.unshift({
        accountCode : '',
        accountName: '',
        docCode: '',
        docClosedAt: '',
        debit : round(this.broughtForward > 0 ? this.broughtForward : 0, 2),
        credit: round(this.broughtForward < 0 ? this.broughtForward * -1 : 0, 2),
        balance: round(this.broughtForward, 2),
        docName: 'ยอดยกมา',
        docRemark: '',
      })

      const data = [{
        sheet: this.sheetname,
        columns: this.columns,
        content
      }]

      return data
    }
  },
  methods: {
    download () {
      const settings = {
        fileName: this.filename,
      }
      xlsx(this.rows, settings)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
