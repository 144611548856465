<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
  
      <div class="dashhead-toolbar">
        <div class=" dashhead-toolbar-item">
  
        </div>
      </div>
    </div>
  
    <hr class="my-3">
  
    <div class="">
      <div class="form-row">
        <DetailAccountInput
          class="col-12"
          :templateType="templateType"
          :accountTrialBalanceType="accountTrialBalanceType"
          select="id"
          v-model="accountId"
          @change="account = $event">
        </DetailAccountInput>
      </div>
  
      <div class="form-row">
        <sgv-input-date 
          label="เริ่มต้น" 
          class="col-6" 
          isNull 
          v-model="startDate" 
          :validations="[
            {text: 'required!', value: $v.startDate.$dirty && $v.startDate.$error}
          ]">
        </sgv-input-date>
  
        <sgv-input-date 
          label="สิ้นสุด" 
          class="col-6" 
          isNull 
          v-model="endDate" 
          :validations="[
            {text: 'required!', value: $v.endDate.$dirty && $v.endDate.$error}
          ]">
        </sgv-input-date>
      </div>
    
      <div class="mb-3">
        <button 
          type="button" 
          class="btn btn-warning" 
          @click="fetchData">
          ค้นหา
        </button>

        <button 
          type="button" 
          class="btn btn-primary ml-2" 
          @click="toList">
          กลับ
        </button>

        <DetailExcel 
          v-if="docs.length > 0 && account && startDate && endDate"
          class="float-right"
          :docs="docs"
          :account="account"
          :startDate="startDate"
          :endDate="endDate"
          :broughtForward="broughtForward">
        </DetailExcel>
      </div>
    </div>

    <div>
      <DetailTable 
        :broughtForward="broughtForward"
        :carryForward="carryForward"
        :docs="docs">
      </DetailTable>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import DetailTable from './DetailTable.vue'
import DetailExcel from './DetailExcel.vue'
import { required } from 'vuelidate/lib/validators'
import { LIST_DOC } from './graph'
import DetailAccountInput from './DetailAccountInput.vue'


export default {
  components: {
    DetailTable,
    DetailExcel,
    DetailAccountInput
  },
  mixins: [retainMixin],
  props: {
    templateType: {
      type: String,
      required: true
    },
    accountTrialBalanceType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `AccountTrialBalance${this.$form.capitalize(this.accountTrialBalanceType)}Detail`,
      listView: `AccountTrialBalance${this.$form.capitalize(this.accountTrialBalanceType)}List`,
      rFields: ['startDate', 'endDate'],
      startDate: '',
      endDate: '',
      broughtForward: 0,
      carryForward: 0,
      docs: [],
      accountId: null,
      account: null
    }
  },
  validations: {
    accountId: { required },
    startDate: { required },
    endDate: { required }
  },
  methods: {
    fetchData () {
      this.$v.$touch()

      if (this.$v.$invalid) return

      this.broughtForward = 0
      this.carryForward = 0
      this.docs = []

      this.$apollo.query({
        query: LIST_DOC(this.templateType),
        variables: {
          accountTrialBalanceType: this.accountTrialBalanceType,
          accountId: this.accountId,
          startDate: this.startDate,
          endDate: this.endDate
        },
        fetchPolicy: 'no-cache'
      }).then(res => {
        this.broughtForward = res.data.data.broughtForward
        this.carryForward = res.data.data.carryForward
        this.docs = res.data.data.docs
      }).catch(err => this.$toasted.global.error(err))
    },
    toList () {
      this.$router.push({
        name: this.listView
      })
    }
  },
  watch: {
    accountId (accountId) {
      this.broughtForward = 0
      this.carryForward = 0
      this.docs = []

      this.$router.push({
        to: this.$route.name,
        query: {
          startDate: this.$route.query?.startDate,
          endDate: this.$route.query?.endDate,
          accountId: accountId
        }
      })
    }
  },
  created () {
    this.startDate = this.$route.query?.startDate || ''
    this.endDate = this.$route.query?.endDate || ''
    this.accountId = +this.$route.query?.accountId || null

    if (this.accountId && this.startDate && this.endDate) {
      this.fetchData()
    }
  }
}
</script>

<style lang="css" scoped>

</style>
