<template>
  <div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th class="text-success text-right">เดบิต DR</th>
            <th class="text-danger text-right">เครดิต CR</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ยอดยกมา</td>
            <td class="text-success text-right">
              <span v-if="broughtForward > 0">{{ broughtForward | comma }}</span>
              <span v-else>-</span>
            </td>
            <td class="text-danger text-right">
              <span v-if="broughtForward < 0">{{ broughtForward | comma }}</span>
              <span v-else>-</span>
            </td>
          </tr>
          <tr>
            <td>ระหว่างงวด</td>
            <td class="text-success text-right">
              <span v-if="totalDebit > 0">{{ totalDebit | comma }}</span>
              <span v-else>-</span>
            </td>
            <td class="text-danger text-right">
              <span v-if="totalCredit < 0">{{ totalCredit * -1 | comma }}</span>
              <span v-else>-</span>
            </td>
          </tr>
          <tr>
            <td>
              <span class="ml-3">จำนวนเอกสาร: {{totalDoc}}</span>
            </td>
            <td class="text-success text-right">
              <span v-if="totalAmount > 0">{{ totalAmount | comma }}</span>
              <span v-else>-</span>
            </td>
            <td class="text-danger text-right">
              <span v-if="totalAmount < 0">{{ totalAmount * -1 | comma }}</span>
              <span v-else>-</span>
            </td>
          </tr>
          <tr>
            <td>ยอดยกไป</td>
            <td class="text-success text-right">
              <span v-if="carryForward > 0">{{ carryForward | comma }}</span>
              <span v-else>-</span>
            </td>
            <td class="text-danger text-right">
              <span v-if="carryForward < 0">{{ carryForward | comma }}</span>
              <span v-else>-</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <sgv-table
      :rKey="rKey"
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      :rowsPerPage="rowsPerPage"
      :toolbars="toolbars">

      <template slot-scope="{item, hidden, idx}">
        <tr v-if="idx == 0">
          <td v-if="hidden.code" class="no-wrap"></td>
          <td v-if="hidden.closedAt" class=""></td>

          <td v-if="hidden.debit" class="text-success no-wrap">
            <span v-if="broughtForward > 0">{{ broughtForward | comma }}</span>
            <span v-else>-</span>
          </td>
          <td v-if="hidden.credit" class="text-danger no-wrap">
            <span v-if="broughtForward < 0">{{ broughtForward * -1 | comma }}</span>
            <span v-else>-</span>
          </td>
          <td v-if="hidden.balance" class="no-wrap">
            <span v-if="broughtForward >= 0" class="text-success">{{ broughtForward | comma }}</span>
            <span v-if="broughtForward < 0" class="text-danger">{{ broughtForward * -1 | comma }}</span>
          </td>

          <td v-if="hidden.name" class="no-wrap">ยอดยกมา</td>
        </tr>
        <tr>
          <td v-if="hidden.code" class="no-wrap">
            <router-link 
              class="text-decoration-none" 
              :to="toDoc(item)">
              {{ idx+1 }}. {{ item.code }}
            </router-link>
          </td>
          <td v-if="hidden.closedAt" class="">
            {{ item.closedAt | date }}
          </td>

          <td v-if="hidden.debit" class="text-success no-wrap">
            <span v-if="item.debit > 0">{{ item.debit | comma }}</span>
            <span v-else>-</span>
          </td>
          <td v-if="hidden.credit" class="text-danger no-wrap">
            <span v-if="item.credit > 0">{{ item.credit | comma }}</span>
            <span v-else>-</span>
          </td>
          <td v-if="hidden.balance" class="no-wrap">
            <span v-if="item.balance >= 0" class="text-success">{{ item.balance | comma }}</span>
            <span v-if="item.balance < 0" class="text-danger">{{ item.balance * -1 | comma }}</span>
          </td>

          <td v-if="hidden.name" class="no-wrap">
            {{ item.name }}
            <div v-if="item.remark">
              <small class="text-primary">{{ item.remark }}</small>
            </div>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'

export default {
  mixins: [retainMixin],
  props: {
    broughtForward: {
      type: Number,
      required: true
    },
    carryForward: {
      type: Number,
      required: true
    },
    docs: {
      type: Array,
      required: true,
      default () {
        return []
      }
    }
  },
  data() {
    return {
      rKey: `AccountTrialBalance${this.$form.capitalize(this.accountTrialBalanceType)}DetailTable`,
      rFields: ['filter', 'options'],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'closedAt', 'debit', 'credit', 'balance', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      rowsPerPage: [
        { text: 'ทั้งหมด', value: null }
      ],
      toolbars: [
        { value: 'filter', icon: 'cog', class: 'text-warning' }
      ],
    }
  },
  computed: {
    headers () {
      return [
        { text: `เอกสาร`, value: 'code' },
        { text: 'วันที่', value: 'closedAt' },
        { text: `เดบิต`, value: 'debit', class: ['no-wrap'] },
        { text: `เครดิต`, value: 'credit', class: ['no-wrap'] },
        { text: `คงเหลือ`, value: 'balance', class: ['no-wrap'] },
        { text: 'รายละเอียด', value: 'name' },
      ]
    },
    docsByFilter () {
      if (!this.options.search) return this.docs

      const search = this.options.search.toLowerCase()

      return this.docs.filter(d => {
        return d.code.toLowerCase().indexOf(search) !== -1 ||
        d.name.toLowerCase().indexOf(search) !== -1 ||
        d.remark.toLowerCase().indexOf(search) !== -1
      })
    },
    items () {
      return this.docsByFilter.map(doc => {
        return {
          ...doc,
          debit: doc.amount > 0 ? doc.amount : 0,
          credit: doc.amount < 0 ? doc.amount * -1 : 0,
        }
      })
    },
    totalDoc () {
      return this.docsByFilter.length
    },
    totalDebit () {
      return this.docsByFilter.filter(v => v.amount > 0).reduce((t,v) => t += +v.amount, 0)
    },
    totalCredit() {
      return this.docsByFilter.filter(v => v.amount < 0).reduce((t, v) => t += +v.amount, 0)
    },
    totalAmount () {
      return this.totalDebit + this.totalCredit
    }
  },
  methods: {
    toDoc(doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {
          docId: doc.id
        }
      }
    },
  },
  watch: {
    filter: 'setRetaining',
    options: 'setRetaining'
  }
}
</script>